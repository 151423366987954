<template>
  <div class="list">
    <list-template
      :loading="loading"
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :current-page="page"
      @onSearch="onSearch"
      @onReset="search = null"
      @onChangePage="changeCurrentPage"
      @onHandle="tableHandle"
    ></list-template>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: true,
      searchConfig: [
        {
          tag: 'select',
          placeholder: '请选择校区',
          prop: 'school_id',
          options: [],
          change: (val, search) => {
            this.search = { ...search }
          }
        },
        {
          prop: 'clerk_name',
          placeholder: '搜索教师姓名'
        }
      ],
      tableConfig: [
        {
          prop: 'checked',
          label: '审核状态',
          render(row) {
            let checked = parseInt(row.checked)
            return checked === 0 ? '待审核' : checked === 1 ? '通过' : '未通过'
          }
        },
        {
          prop: 'school_name',
          label: '校区'
        },
        {
          prop: 'clerk_name',
          label: '教师姓名'
        },
        {
          prop: 'subject_name',
          label: '教学科目'
        },
        {
          prop: 'examine_time',
          label: '审核时间'
        },
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          buttons: (row)=>{
            let checked = parseInt(row.checked)
            return checked == 0 ? [{ type:"approve", text:"审核" }] : [{ type:"view", text:"查看" }]
          }
        }
      ],
      search: null,
      tableData: [],
      total: 0,
      page: 1
    }
  },
  methods: {
    getOption() {
      const obj = this.$store.getters.userInfo.school_arr
      let options = []
      Object.keys(obj).forEach(key => {
        options.push({ label: obj[key], value: key })
      })
      this.searchConfig[0].options = options
    },
    tableHandle(row) {
      this.$router.push({ path: './details', query: { type: row.checked, id: row.id } })
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.page = e
      this.getData()
    },
    // 获取数据
    getData() {
      let { search, page } = this
      this.loading = true
      this.$_axios
        .get('/teacher/examine', { params: { ...search, page } })
        .then(res => {
          let { data } = res.data
          this.tableData = data
          let { total } = this.$tools.getPaginationInfo(res.headers)
          this.total = total
        })
        .finally(() => (this.loading = false))
    },
    // 搜索功能
    onSearch(val) {
      this.page = 1
      this.search = val
      this.getData()
    }
  },
  mounted() {
    this.getData()
    this.getOption()
  },
  activated() {
    this.getData()
  }
}
</script>

<style scoped></style>
